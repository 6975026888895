<template>
  <section id="our-features" class="be-our-features">
    <div class="container">
      <h2 class="title" v-text="content.title"/>
      <div class="divider"/>
      <h3 class="subtitle" v-text="content.subtitle"/>
      <div class="features">
        <div class="feature">
          <div class="frame"/>
          <img alt="feature" src="../assets/images/create-team.jpeg"/>
          <div class="feature-content">
            <h3>Create Team</h3>
            <p>Increase competition by creating teams people feel happy to be part of.</p>
          </div>
        </div>
        <div class="feature">
          <div class="frame"/>
          <img alt="feature" src="../assets/images/find-team.jpeg"/>
          <div class="feature-content">
            <h3>Find Team</h3>
            <p>Easily find teams to compete in your favourite sports.</p>
          </div>
        </div>
        <div class="feature">
          <div class="frame"/>
          <img alt="feature" src="../assets/images/rankings.png"/>
          <div class="feature-content">
            <h3>Rankings</h3>
            <p>Compete with the best, move upwards, evaluate your development, become the best.</p>
          </div>
        </div>
        <div class="feature">
          <div class="frame"/>
          <img alt="feature" src="../assets/images/find-player.png"/>
          <div class="feature-content">
            <h3>Find Player</h3>
            <p>Need more players for your team? Players without ones waiting for you.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurFeatures',

  props: {
    content: {
      type: Object,
      default: () => ({
        title: '',
        subtitle: '',
        mission: '',
        vision: ''
      })
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/our-features';
</style>
