<template>
<div class="be-detail-page remove-data">
    <hero-banner id="home" mode="fifty" :content="heroContent"/>
    <div class="container">
        <p>Consider before submitting: </p>
        <ul>
        <li>The progress you have made in your carrier in all the sports will be lost.</li>
        <li>Restoring deleted data is not possible after the player is removed from the Backfield Elite.</li>
        <li>Using the same email for creating new player and starting career from scratch is not allowed. We will store removed email for this purpose</li>
        </ul>
        <form>
            <input
              v-model="formData.user_email"
              type="email"
              id="email"
              name="email"
              placeholder="User email*"
              required
              :class="{ 'error': showError && formData.user_email === '' }"
            >
            <p v-if="success" class="text success">
              We got your request!
            </p>
            <p v-if="error" class="text error">
              Something went wrong, please try again!
            </p>
            <button type="button" :disabled="buttonDisable" name="submit" id="submit" class="submit-button" @click="validateCaptcha()">Submit</button>
          </form>
    </div>
</div>
</template>

<script>
import HeroBanner from '@/components/HeroBanner.vue'

export default {
  name: 'PolicyView',

  components: { HeroBanner },

  data () {
    return {
      heroContent: {
        title: 'Remove Player Data'
      },
      formData: {
        user_email: ''
      },
      showError: false,
      success: false,
      error: false,
      buttonDisable: false
    }
  },

  methods: {
    async validateCaptcha () {
      try {
        if (!window.grecaptcha) {
          await new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = 'https://www.google.com/recaptcha/api.js?render=6LcGl0QqAAAAABTOPnkw66BTs3zF4HsTqSCJAdhj'
            script.onload = resolve
            document.head.appendChild(script)
          })
        }

        // Execute reCAPTCHA and get the token
        const token = await new Promise((resolve, reject) => {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LcGl0QqAAAAABTOPnkw66BTs3zF4HsTqSCJAdhj').then(resolve).catch(reject)
          })
        })

        if (token) {
          this.sendForm()
          return
        }

        this.showError = true
      } catch (error) {
        console.error('Error during CAPTCHA verification:', error)
      }
    },

    sendForm () {
      if (this.formData.user_email === '') {
        this.showError = true

        return
      }

      this.showError = false
      this.buttonDisable = true
      const axios = require('axios')

      axios
        .post('https://application.backfieldelite.com/management/app_user/erase', this.formData)
        .then(() => { this.success = true })
        .catch((error) => {
          this.error = error
          this.buttonDisable = true
        })

      this.formData = {
        user_email: ''
      }
    // const recaptchaResponse = window.grecaptcha.getResponse()
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/detailpage.scss';
</style>
