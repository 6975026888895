<template>
  <section id="download" class="be-download-app">
    <div class="content">
      <h2 class="title">
        Now Available
      </h2>
      <div class="divider"/>
      <p class="subtitle">
        Download the application for your mobile.
      </p>
      <div class="logos">
        <a target="_blank" aria-label="Download from app store" href="https://apps.apple.com/gb/app/backfield-elite/id1623995491">
          <img width="300px" src="../assets/images/apple.webp" alt="Download from app store" />
        </a>
        <a target="_blank" aria-label="Download from play store" href="https://play.google.com/store/apps/details?id=com.backfieldelite">
          <img width="300px" src="../assets/images/android.webp" alt="Download from play store" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DownloadApp'
}
</script>

<style lang="scss">
@import '../assets/styles/components/download-app';
</style>
