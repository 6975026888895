<template>
  <div class="be-detail-page blog-view">
    <hero-banner
      id="home"
      mode="fifty"
      :content="heroContent"
    />
    <blog-content :show-header="false" :is-blog-section="false"/>
    <download-app/>
  </div>
</template>

<script>
import HeroBanner from '@/components/HeroBanner.vue'
import BlogContent from '@/components/BlogContent.vue'
import DownloadApp from '@/components/DownloadApp.vue'

export default {
  name: 'BlogView',

  components: { HeroBanner, BlogContent, DownloadApp },

  data () {
    return {
      heroContent: {
        title: 'Blogs'
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/detailpage.scss';
</style>
