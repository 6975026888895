<template>
  <div :class="$route.name">
    <site-header/>
    <router-view/>
    <site-footer/>
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
  name: 'App',

  components: {
    SiteHeader,
    SiteFooter
  }
}
</script>

<style lang="scss">
@import 'assets/styles/app';
</style>
