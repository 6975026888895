<template>
  <section class="be-hero-banner" :class="mode">
    <div class="content">
      <h2 class="title" v-text="content.title"/>
      <p class="text" v-text="content.text"/>
      <div v-if="showBreadcrumbs" class="breadcrumbs">
        <router-link to="/">Home</router-link> /
        <router-link to="/blogs">Blogs</router-link> /
        <span v-text="content.title"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroBanner',

  props: {
    mode: {
      type: String,
      default: 'full'
    },
    content: {
      type: Object,
      default: () => ({
        title: '',
        text: ''
      })
    },
    showBreadcrumbs: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/hero-banner';
</style>
