<template>
  <section id="about-us" class="be-about-us container">
    <div class="content">
      <h2 class="title dark" v-text="content.title"/>
      <div class="divider"/>
      <h3 class="subtitle dark" v-text="content.subtitle"/>
      <div class="split-content">
        <p class="text dark" v-text="content.mission"/>
        <p class="text dark" v-text="content.vision"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUs',

  props: {
    content: {
      type: Object,
      default: () => ({
        title: '',
        subtitle: '',
        mission: '',
        vision: ''
      })
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/about-us';
</style>
