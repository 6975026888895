<template>
  <section
    id="blog-content"
    class="be-blog-content container"
    :class="{ 'blog-section': isBlogSection }
  ">
    <div class="content text-center">
      <div v-if="showHeader">
        <h2 class="title dark">From the blog</h2>
        <div class="divider"/>
        <h3 class="subtitle dark">Only best stories</h3>
        <p class="text dark">
          <router-link to="/blogs" class="see-all">See All</router-link>
        </p>
      </div>
      <div class="split-content">
        <router-link :to="`/blogs/${blog.link}`"
          v-for="(blog, index) in blogs"
          :key="index"
          class="blog"
        >
          <img :src="blog.image" :alt="blog.title"/>
          <div class="blog__content">
            <span v-if="blog.date" v-text="blog.date"/>
            <h2 v-text="blog.title"/>
            <p v-text="`${blog.body}...`"/>
            <router-link :to="`/blogs/${blog.link}`">Read More</router-link>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BlogContent',

  props: {
    maxLimit: {
      type: [Number, Boolean],
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    isBlogSection: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      blogs: []
    }
  },

  mounted () {
    this.getBlogs()
  },

  methods: {
    async getBlogs () {
      const response = await fetch('https://application.backfieldelite.com/management/blog/get')
      const data = await response.json()

      data.forEach((blog, index) => {
        data[index].date = this.formatDate(blog.date) === 'Invalid Date' ? false : this.formatDate(blog.date)
      })

      this.blogs = data.reverse()

      if (this.maxLimit) {
        this.blogs = this.blogs.splice(0, this.maxLimit)
      }
    },

    formatDate (date) {
      date = date.split('-')
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const today = new Date(date[2], date[1] - 1, date[0])

      return today.toLocaleDateString('en-GB', options)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/blog-content';
</style>
