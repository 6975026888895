import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PolicyView from '../views/PolicyView.vue'
import TermsView from '../views/TermsView.vue'
import BlogView from '../views/BlogView.vue'
import BlogDetailView from '../views/BlogDetailView.vue'
import RemovePlayer from '../views/RemovePlayerView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/privacy-policy',
    name: 'policy',
    component: PolicyView
  },
  {
    path: '/remove-player-data',
    name: 'remove-player',
    component: RemovePlayer
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: BlogView
  },
  {
    path: '/blogs/:id',
    name: 'blogs-detail',
    component: BlogDetailView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
