<template>
  <section id="screenshots" class="image-carousel">
    <div class="image-carousel__background">
      <img alt="Our features" src="../assets/images/ss.png" />
    </div>
    <div class="image-carousel__content text-center">
      <h3 class="subtitle dark" v-text="content.subtitle"/>
      <p class="text dark" v-text="content.description"/>
      <div class="col-md-12">
        <swiper
          :modules="modules"
          :slides-per-view="2.5"
          :space-between="5"
          :breakpoints="{
            1024: {
              slidesPerView: 2.5,
            },
          }"
          class="image-carousel__slider"
        >
          <swiper-slide v-for="(image, index) in content.images" :key="index">
            <img alt="Screenshots from app" :src="require(`../assets/images/screenshot/mock${image}-min.png`)" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Navigation, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

export default {
  name: 'ImageCarousel',

  components: { Swiper, SwiperSlide },

  props: {
    content: {
      type: Object,
      default: () => ({
        title: '',
        subtitle: '',
        mission: '',
        vision: ''
      })
    }
  },

  setup () {
    return {
      modules: [Navigation, Scrollbar, A11y]
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/components/image-carousel';
</style>
