<template>
  <div class="be-homepage">
    <hero-banner id="home" :content="heroContent"/>
    <about-us :content="aboutUsContent"/>
    <our-features :content="featuresContent"/>
    <image-carousel :content="imageCarousel"/>
    <download-app/>
    <blog-content :max-limit="2"/>
  </div>
</template>

<script>
import HeroBanner from '@/components/HeroBanner.vue'
import AboutUs from '@/components/AboutUs.vue'
import OurFeatures from '@/components/OurFeatures.vue'
import ImageCarousel from '@/components/ImageCarousel.vue'
import DownloadApp from '@/components/DownloadApp.vue'
import BlogContent from '@/components/BlogContent.vue'

export default {
  name: 'HomeView',

  components: {
    HeroBanner,
    AboutUs,
    OurFeatures,
    ImageCarousel,
    DownloadApp,
    BlogContent
  },

  data () {
    return {
      heroContent: {
        title: 'Backfield Elite',
        text: 'Score the ultimate play: Build, connect, compete! Your team, your game, your victory – All in One App!'
      },
      aboutUsContent: {
        title: 'Who we are',
        subtitle: 'Evolve, Refine, Master',
        mission: 'We love sports and active lifestyle. At Backfield we stick to the saying - "Healthy spirit in the healthy body". Trying to find competition for ourselves, we came up with an idea and decided to join the world with goal of healthy competing, and developed the idea into something that everyone can benefit from.',
        vision: 'Everyone has been stuck at home for a long time recently, sports have become something marginal, especially team sports. It is time to make the culture of backfield competitions mainstream again. There has never been time with more necessity of enjoying the things in our lives that are healthy and beneficial.'
      },
      featuresContent: {
        title: 'Our Features',
        subtitle: 'and more...'
      },
      imageCarousel: {
        subtitle: 'Screenshots',
        description: 'Our awesome app screenshot gallery here.',
        images: [1, 2, 3, 4]
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/homepage.scss';
</style>
